import store from '../store/store'
import axios from 'axios'
import helpers from '@/utils/helpers'

import shopSetting from './shopSetting'

// copy
import clipboard from 'clipboard'

export default {
  mounted() {
    window.addEventListener('DOMContentLoaded', this.device)
  },
  methods: {
    call_api: (method, url, data, multipart = false) => {
      const config = {
        method: method,
        url: store.state.app.apiUrl + store.state.app.apiPath + url,
        data: data
      }
      if (multipart) {
        config.headers = { 'content-type': 'multipart/form-data' }
      }
      try {
        const res = axios(config)

        return res
      } catch (e) {
        console.log(e)
      }
    },
    static_asset: (path = '') => path,
    snack: (data = {}) => {
      store.commit('snackbar/createSnack', data)
    },
    format_price: (amount = 0) => {
      const currency_setting = store.getters['app/generalSettings'].currency
      const exchange_rate = Number(currency_setting.exchange_rate || 1)
      amount = parseFloat(amount * exchange_rate)
      amount = typeof amount == 'number' ? amount : 0.0

      const current__code = currency_setting.symbol

      let formated_price = amount.toFixed(currency_setting.no_of_decimals)

      if (currency_setting.truncate_price == 1) {
        if (formated_price > 999999.99 && formated_price < 1000000000) {
          formated_price =
            (formated_price / 1000000).toFixed(
              currency_setting.no_of_decimals
            ) + 'M'
        } else if (formated_price > 1000000000) {
          formated_price =
            (formated_price / 1000000000).toFixed(
              currency_setting.no_of_decimals
            ) + 'B'
        }
      }
      formated_price =
        currency_setting.decimal_separator === '1'
          ? formated_price.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          : formated_price
              .replace('.', ',')
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')

      switch (currency_setting.symbol_format) {
        case '2':
          formated_price = formated_price + current__code
          break
        case '3':
          formated_price = current__code + ' ' + formated_price
          break
        case '4':
          formated_price = formated_price + ' ' + current__code
          break
        default:
          formated_price = current__code + formated_price
      }
      return formated_price
    },
    is_addon_activated: (key = '') => {
      const addon = store.getters['app/addons'].find(
        (addon) => addon.unique_identifier === key
      )
      return (
        key && key != '' && typeof addon != 'undefined' && addon.activated == 1
      )
    },
    is_empty_obj: (obj = {}) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object
    },
    discount_percent: (price = 0, discount_price = 0) => {
      price = parseFloat(price)
      discount_price = parseFloat(discount_price)

      return price > discount_price
        ? Math.round((100 * (price - discount_price)) / price)
        : 0
    },
    get_random_color: () => {
      var colors = Array(
        'red',
        'pink',
        'purple',
        'deep-purple',
        'indigo',
        'blue',
        'blue',
        'light-blue',
        'cyan',
        'teal',
        'green',
        'light-green',
        'lime',
        'yellow',
        'amber',
        'orange',
        'deep-orange',
        'brown',
        'blue-grey',
        'grey'
      )
      return colors[Math.floor(Math.random() * colors.length)]
    },
    device() {
      const viewportWidth = window.innerWidth
      if (viewportWidth >= 1904) {
        return 'xl'
      } else if (viewportWidth < 1904 && viewportWidth >= 1264) {
        return 'lg'
      } else if (viewportWidth < 1264 && viewportWidth >= 960) {
        return 'md'
      } else if (viewportWidth < 960 && viewportWidth >= 600) {
        return 'sm'
      } else {
        return 'xs'
      }
    },
    $optional: (p) => eval('this.' + p),
    setSession: (key, status) => {
      const now = new Date()
      const item = {
        status: status,
        expiry: now.getTime() + 3600000
      }

      localStorage.setItem(key, JSON.stringify(item))
    },
    checkSession: (key) => {
      let item = {}
      if (localStorage.getItem(key)) {
        item = localStorage.getItem(key)
        item = JSON.parse(item)
      }
      const now = new Date()

      return typeof item.expiry == 'undefined' || now.getTime() > item.expiry
        ? ''
        : item.status
    },

    copy() {
      const copyCallBack = new clipboard('#copy')

      copyCallBack.on('success', (e) => {
        this.snack({
          message: this.$t('seller_common_copy_successfully')
        })

        copyCallBack.destroy()
      })

      copyCallBack.on('error', () => {
        this.snack({
          message: this.$t('something_went_wrong')
        })

        copyCallBack.destroy()
      })
    },

    addResourceImage: (src) => {
      if (!src) return helpers.imagePlaceholder()
      const isHttps = src.indexOf('https://') > -1
      const isHttp = src.indexOf('http://') > -1
      const isStatic = src.indexOf('@/assets/') > -1

      if (isHttp || isHttps) {
        return src
      }

      if (isStatic) {
        return src
      }

      return shopSetting.imageUrl + src
    }
  }
}
