import Mixin from '../../utils/mixin'
import shopSetting from '@/utils/shopSetting'

const loadState = () => ({
  appName: shopSetting.appName,
  appMetaTitle: shopSetting.appMetaTitle,
  appLogo: shopSetting.appLogo,
  appUrl: shopSetting.appUrl,
  demoMode: shopSetting.demoMode,
  appLanguage: shopSetting.appLanguage,
  paymentMethods: shopSetting.paymentMethods,
  offlinePaymentMethods: shopSetting.offlinePaymentMethods,
  userLanguage:
    localStorage.getItem('shopSelectedLanguage') || shopSetting.appLanguage,
  availableCountries: ['US'],
  allLanguages: localStorage.getItem('shopAllLanguage')
    ? JSON.parse(localStorage.getItem('shopAllLanguage'))
    : shopSetting.allLanguages,
  allCurrencies: shopSetting.allCurrencies,
  generalSettings: shopSetting.general_settings,
  addons: shopSetting.addons,
  banners: shopSetting.banners,
  apiPath: '/api/v1/',
  apiUrl: shopSetting.apiUrl,
  routerLoading: true,
  refundSettings: shopSetting.refundSettings,
  productQuerries: [],
  unseenProductQuerries: 0,
  registerType: '1', //  1.邮箱 2.手机号
  initSetting: {}, // 图片资源域名

  // 商家跳转路径
  h5Url: '',

  // 货币单位列表
  units: [],

  // 邮箱是否需要验证
  emailVerification: true,

  // 网站 icon
  siteIcon: '/favicon.ico',

  // 网站 url
  siteUrl: '',

  // 产品查询消息
  productMessage: 0,

  // 商家app下载地址
  sellerAppDownUrl: '',

  // 商家登录页面
  sellerH5Url: '',

  // 客服链接
  contactUrl: '',

  // 关注开关
  isShowConcern: false,

  // 用户 id 开关
  showIdSwitch: false
})
export default {
  namespaced: true,
  state: loadState(),
  getters: {
    showContern(state) {
      return state.isShowConcern
    },

    // 用户 id 开关
    showIdSwitch(state) {
      return state.showIdSwitch
    },

    unit(state) {
      const currency = state.generalSettings.currency.code

      return state.units.find((unit) => currency === unit.code)
    },

    // 客服链接
    contactUrl(state) {
      return state.contactUrl
    },

    getSellerAppDownUrl(state) {
      return state.sellerAppDownUrl
    },

    getSellerH5Url(state) {
      return state.sellerH5Url
    },

    // 邮箱验证
    emailVerification(state) {
      return state.emailVerification
    },

    siteUrl(state) {
      return state.siteUrl
    },

    // 网站 icon
    siteIcon(state) {
      return state.siteIcon
    },

    units(state) {
      return state.units
    },

    h5Url(state) {
      return state.h5Url
    },

    appName(state) {
      return state.appName
    },
    appMetaTitle(state) {
      return state.appMetaTitle
    },
    appLogo(state) {
      return state.appLogo
    },
    appUrl(state) {
      return state.appUrl.slice(-1) == '/'
        ? state.appUrl.slice(0, state.appUrl.length - 1)
        : state.appUrl
    },
    demoMode(state) {
      return state.demoMode
    },
    apiPath(state) {
      return state.apiPath
    },
    appLanguage(state) {
      return state.appLanguage
    },
    userLanguage(state) {
      return state.userLanguage
    },
    userLanguageObj(state) {
      return state.allLanguages.find(
        (language) => language.code === state.userLanguage
      )
    },
    paymentMethods(state) {
      return state.paymentMethods
    },
    offlinePaymentMethods(state) {
      return state.offlinePaymentMethods
    },
    availableCountries(state) {
      return state.availableCountries
    },
    allLanguages(state) {
      return state.allLanguages
    },
    allCurrencies(state) {
      return state.allCurrencies
    },
    generalSettings(state) {
      return state.generalSettings
    },
    banners(state) {
      return state.banners
    },
    addons(state) {
      return state.addons
    },
    routerLoading(state) {
      return state.routerLoading
    },
    refundSettings(state) {
      return state.refundSettings
    },
    getProductQuerries(state) {
      return state.productQuerries
    },
    getUnseenProductQuerries(state) {
      return state.unseenProductQuerries
    },
    getShopRegisterType(state) {
      return state.registerType
    },

    getProductMessage(state) {
      return state.productMessage
    }
  },
  mutations: {
    setShowIdSwitch(state, payload) {
      state.showIdSwitch = payload
    },

    setContactUrl(state, url) {
      state.contactUrl = url
    },
    setProductQuerries(state, data) {
      state.productQuerries = data
      const unseenData = data.filter((data) => {
        return data.sender_viewed == 0
      })
      state.unseenProductQuerries = unseenData.length
    },
    setLanguage(state, lang) {
      if (state.userLanguage !== lang) {
        state.userLanguage = lang

        localStorage.removeItem('shopSelectedLanguage')
        localStorage.setItem('shopSelectedLanguage', lang)
      }
    },
    removeLanguage(state) {
      state.userLanguage = state.appLanguage
      localStorage.removeItem('shopSelectedLanguage')
    },
    setRouterLoading(state, status) {
      state.routerLoading = status
    },

    setGeneralSettingsCurrency(state, currency) {
      if (state.generalSettings) {
        state.generalSettings.currency.symbol = currency.symbol
      }
    },

    setSetting(state, payload) {
      const {
        appName,
        addons,
        appMetaTitle,
        appLogo,
        appUrl,
        demoMode,
        appLanguage,
        paymentMethods,
        offlinePaymentMethods,
        availableCountries,
        allLanguages,
        allCurrencies,
        general_settings,
        banners,
        refundSettings,
        register_type,
        seller_login_url,
        email_verification,
        site_icon,
        h5_url,
        seller_h5_url,
        seller_app_down_url
      } = payload

      state.sellerAppDownUrl = seller_app_down_url
      state.sellerH5Url = seller_h5_url

      state.appName = appName
      state.addons = addons

      state.appMetaTitle = appMetaTitle
      state.appLogo = appLogo
      state.appUrl = appUrl
      state.demoMode = demoMode
      state.appLanguage = appLanguage
      state.paymentMethods = paymentMethods
      state.offlinePaymentMethods = offlinePaymentMethods

      state.availableCountries =
        availableCountries.length > 0 ? availableCountries : ['US']

      // 如果获取到的语言列表与本地缓存的列表不一致，则更新本地缓存列表
      if (
        localStorage.getItem('shopAllLanguage') !== JSON.stringify(allLanguages)
      ) {
        state.allLanguages = allLanguages

        localStorage.setItem('shopAllLanguage', JSON.stringify(allLanguages))
      }

      state.allCurrencies = allCurrencies

      state.h5Url = seller_login_url
      state.siteUrl = h5_url
      state.emailVerification = !!email_verification
      state.siteIcon = site_icon

      // const currency = localStorage.getItem('currency')

      // if (currency && currency !== 'undefined') {
      //   general_settings.currency.symbol = currency
      // }
      // const currency = general_settings.currency
      // localStorage.setItem('currency', currency.symbol)

      state.generalSettings = general_settings
      state.banners = banners
      state.refundSettings = refundSettings
      state.registerType = register_type

      state.initSetting = payload
    },

    setUnits(state, payload) {
      state.units = payload
    },

    setProductMessage(state, payload) {
      state.productMessage = payload
    },

    setConcern(state, payload) {
      state.isShowConcern = payload
    }
  },
  actions: {
    setLanguage({ commit }, lang) {
      commit('setLanguage', lang)
    },
    removeLanguage({ commit }) {
      commit('removeLanguage')
    },

    // 获取产品查询信息
    async fetchProductMessage({ commit }) {
      try {
        if (this.getters['auth/isAuthenticated']) {
          const {
            data: {
              success,
              data: { count }
            }
          } = await Mixin.methods.call_api('get', `user/message/count`)

          if (success) {
            commit('setProductMessage', count)
          }
        }
      } catch (error) {
        console.log('fetch product message fail: ', error)
      }
    },

    async fetchProductQuerries({ commit }) {
      if (this.getters['auth/isAuthenticated']) {
        const res = await Mixin.methods.call_api('get', `user/querries`)
        if (res.data.success) {
          commit('setProductQuerries', res.data.data)
        }
      }
    },

    async fetchSetting({ commit }) {
      const res = await Mixin.methods.call_api('get', 'setting/main')
      commit('setSetting', res.data)
    },

    async fetchUnits({ commit }) {
      const res = await Mixin.methods.call_api('get', 'currency/available')

      const unitList = res.data?.data.map((unit) => {
        return {
          name: unit.name,
          value: unit.symbol,
          code: unit.code,
          symbol: unit.symbol
        }
      })

      commit('setUnits', unitList)
    },

    async setCurrentUnit({ state }, unit) {
      await Mixin.methods.call_api('post', 'currency/select', {
        currency_code: unit.code
      })

      console.log(state)
      // commit('setGeneralSettingsCurrency', unit)
    },

    // 获取关注开关
    async fetchShowConcern({ commit }) {
      const { data } = await Mixin.methods.call_api(
        'get',
        'get-business-settings/follow_switch'
      )

      commit('setConcern', data.data.follow_switch === 'on')
    },

    // 获取用户开关 id
    async fetchIdSwitch({ commit }) {
      try {
        const {
          data: {
            data: { show_id_switch }
          }
        } = await Mixin.methods.call_api(
          'get',
          'get-business-settings/show_id_switch'
        )

        commit('setShowIdSwitch', show_id_switch === 'on')
      } catch (error) {
        console.log('fetch id switch fail: ', error)
      }
    }
  }
}
